import React from 'react'
import {Alert} from 'antd'
import Layout from '../components/layout'
import {Link} from 'gatsby'
import SEO from '../components/seo'

const FAQ = () => (
    <Layout>
         <SEO title="FAQ" description="Answers to the most frequent asked questions about Emaildrop."/>
        <main>
            <div className="main-wrapper">
                <h1 className="title">Frequently Asked Questions</h1>
                <div className="faq">
                    <div className="contents">
                        <div className="content">
                            <h2>1) What is Emaildrop.io used for?</h2>
                            <div className="paragraph">
                                <p>
                                    Emaildrop is a free disposable email provider. Ever wanted to 
                                    sign up to a website without providing your real email address 
                                    or just wanted to make a one-off purchase? Then Emaildrop serves as
                                    a great way to generate email addresses without passwords that you
                                    can then provide to anybody that you don't trust. That way you're
                                    protected from spam and if a website that you use ever get's hacked 
                                    and their users email addresses are exposed, it won't ever matter 
                                    for you.
                                </p>
                            </div>
                        </div>
                        <div className="content">
                            <h2>2) How long do emails live on the server before being deleted</h2>
                            <div className="paragraph">
                                <p>
                                    Every email has a time to live (TTL) of one hour. No email can be recovered 
                                    after it's TTL expires since we don't keep backups longer than one hour.
                                </p>
                                <Alert
                                description="Emails that are deleted by the user will be deleted immediated 
                                from our servers regardless of whether the TTL has passed or not."
                                type="warning"
                                showIcon
                                />
                            </div>
                        </div>
                        <div className="content">
                            <h2>3) Does Emaildrop's inbox update live?</h2>
                            <div className="paragraph">
                                <p>
                                    Yep, so there's no need to keep hitting the refresh button :).
                                </p>
                            </div>
                        </div>
                        <div className="content">
                            <h2>4) Do I need to generate an email address first before sharing it?</h2>
                            <div className="paragraph">
                                <p>
                                    Nope. You give any @emaildrop.io email address you like to a website that you 
                                    don't trust and then head over to <Link to="/">https://emaildrop.io</Link>
                                    , click custom and then enter the username you provided to the website and any emails
                                    sent to that email address should be there. There's no such thing as "activating" a @emaildrop.io email address 
                                    since they're all already activated and ready to use!
                                </p>
                            </div>
                        </div>
                        <div className="content">
                            <h2>5) Can I make my @emaildrop.io email address private?</h2>
                            <div className="paragraph">
                                <p>
                                    Nope. Every @emaildrop.io email address is public and accessable to anybody as long as 
                                    they change their username to a particular @emaildrop.io email address. It was designed that
                                    way to make Emaildrop as simple as possible and to ensure users only use it as a disposable email
                                    service. Ofcouse, if use a @emaildrop.io with an extremely long/unique username it's highly
                                    unlikely that anybody else will ever think of it :). That's way users that visit the website for 
                                    the first time are issued email addresses with their username's being a bunch of random letters
                                    and numbers.
                                </p>
                            </div>
                        </div>
                        <div className="content">
                            <h2>6) Do you have an API?</h2>
                            <div className="paragraph">
                                <p>
                                    Yep, we have a GraphQL API that is completely free and public. Head over
                                    to our <Link to="/api">API page</Link> to find out more.
                                </p>
                            </div>
                        </div>
                        <div className="content">
                            <h2>7) Can I send emails using @emaildrop.io email addresses?</h2>
                            <div className="paragraph">
                                <p>
                                    Nope and we have no plans to support such a feature due to fraud issues.
                                </p>
                            </div>
                        </div>
                        <div className="content">
                            <h2>8) How do I extend the TTL for my emails?</h2>
                            <div className="paragraph">
                                <p>
                                    Such a feature does not exist yet.
                                </p>
                            </div>
                        </div>
                        <div className="content">
                            <h2>9) Can I recieve emails with attachments</h2>
                            <div className="paragraph">
                                <p>
                                    Yep, every kind of email will land in your inbox regardless of whether it
                                    contains attachments or not. As a result, you need to be careful when opening 
                                    attachments from untrustworthy sources. All attachments can be downloaded by the 
                                    user as long as the download started before the email's TTL expires.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </Layout>
)

export default FAQ;